import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import Papa, { ParseResult } from 'papaparse';
// import * as Sentry from '@sentry/react';

import { InfoLine } from 'components/Form';
import { TUploadParams } from 'components/Uploader';
import Uploader from 'components/Uploader/async';
import Spinner from 'fragments/Spinner';
import fetch from 'utils/fetch';
import useStore from 'globalstate';
import { API_URL } from 'constants/app';

import SuccessModal from './Success';

const datasetOptions = {
  minSize: 1, //1024 * 200, // 200kb
  maxSize: 1024 * 1024, // 1mb
};
const notebookOptions = {
  minSize: 1, // 1byte
  maxSize: 1024 * 1024 * 10, // 5mb
};
// const datasetConstraints = {
//   length: 28000,
//   lengthMin: 27999,
//   lengthMax: 28001,
//   headers: ['id', 'risk_flag'],
// };
// type TData = { id: string; risk_flag: string };

interface IProps {
  onComplete: (score: string) => void;
}

export default function (props: IProps) {
  const [error, seterror] = useState<string>();
  const [showSuccessModal, setshowSuccessModal] = useState<boolean>(false);
  const [currentStatus, setcurrentStatus] = useState<string>();
  const [score, setscore] = useState<string>();
  const [submissionId, setsubmissionId] = useState<string>();
  const [datasetUploadParams, setdatasetUploadParams] = useState<TUploadParams>();
  const [notebookUploadParams, setnotebookUploadParams] = useState<TUploadParams>();
  const [doUpload, setdoUpload] = useState<boolean>(false);
  const [dataset, setdataset] = useState<File | null>(null);
  const [dataseterror, setdataseterror] = useState<string>();
  const [notebook, setnotebook] = useState<File | null>(null);
  const [uploading, setuploading] = useState<boolean>(false);
  const [uploadedDataset, setuploadedDataset] = useState<boolean>(false);
  const [uploadedNotebook, setuploadedNotebook] = useState<boolean>(false);
  const [resetTrigger, setresetTrigger] = useState<number>();

  const [State] = useStore();
  const { contestId } = State;

  const UPLOAD_URL = `${API_URL}/contest/submit-entry`;
  const CONFIRM_SUBMISSION_URL = `${API_URL}/contest/confirm-upload`;

  const onSubmit = async () => {
    if (!dataset) return seterror('Select your dataset csv file to continue');
    if (!notebook) return seterror('Select your notebook file to continue');

    setuploading(true);
    const datasetExtension = notebook.type.split('/')[1];
    const notebookExtension = notebook.type.split('/')[1];
    const resp = await fetch(UPLOAD_URL, {
      body: JSON.stringify({
        contest_id: contestId,
        dataFileFormat: datasetExtension,
        sourceCodeFileFormat: notebookExtension,
      }),
      credentials: 'header',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    try {
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      setcurrentStatus('Fetching submission details');
      setdatasetUploadParams(respJSON.data.signedURL.dataset);
      setnotebookUploadParams(respJSON.data.signedURL.source_code);
      setsubmissionId(respJSON.data.submission_id);
      setcurrentStatus('Uploading dataset and notebook');
      setdoUpload(true);
    } catch (e) {
      onError((e as Error).message);
    }
  };

  const onComplete = async () => {
    setcurrentStatus('Evaluating submission');
    const resp = await fetch(CONFIRM_SUBMISSION_URL, {
      body: JSON.stringify({ submission_id: submissionId }),
      credentials: 'header',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    try {
      setcurrentStatus('');
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const score = respJSON.data.score;
      for (const key in respJSON.data.score) {
        if (!score[key]) continue;
        score[`${key}`] = score[key].toFixed(15);
      }
      setscore(score);
      setshowSuccessModal(true);
      props.onComplete(respJSON.data.score);
    } catch (e) {
      onError((e as Error).message);
    }
  };

  const onError = async (error: string) => {
    setuploading(false);
    setcurrentStatus('');
    seterror(error);
    setdataset(null);
    setnotebook(null);
    setresetTrigger(Date.now());
  };

  // const onSelectDataset = async (dataset: File) => {
  //   const onParseCSV = (results: ParseResult<unknown>, file: File) => {
  //     let datasetConstraintError = '';
  //     // check if rows are within range
  //     if (!(results.data.length > datasetConstraints.lengthMin && results.data.length < datasetConstraints.lengthMax))
  //       datasetConstraintError += ` The number of rows do not match. Expected ${datasetConstraints.length} rows.`;
  //     if (!results.meta.fields) datasetConstraintError += 'Unable to parse csv.';
  //     // check if column headers match
  //     if (results.meta.fields) {
  //       if (results.meta.fields.length !== datasetConstraints.headers.length)
  //         datasetConstraintError +=
  //           ' Columns do not match' + ' Expected 2 columns - ' + `${datasetConstraints.headers.join(', ')}`;
  //       if (!datasetConstraints.headers.every(h => results.meta.fields?.includes(h)))
  //         datasetConstraintError +=
  //           ' Headers do not match. Expected headers are - ' + `${datasetConstraints.headers.join(', ')}.`;
  //     }
  //     if (datasetConstraintError) {
  //       const dataString =
  //         ' length: ' +
  //         `${results.data.length} - ${results.meta.fields?.toString()}` +
  //         ` u-${State.userDetails?.userId}`;
  //       Sentry.captureException(new Error(`${datasetConstraintError} ${dataString}`));
  //       setdataseterror(datasetConstraintError);
  //       return seterror("Dataset doesn't seem to be valid. Please check the sample submission.");
  //     }
  //     return setdataset(file);
  //   };

  //   Papa.parse(dataset, {
  //     complete: onParseCSV,
  //     header: true,
  //     transformHeader: (header: string) => header && header.trim().toLowerCase(),
  //     skipEmptyLines: true,
  //   });
  // };

  const onSelectDataset = async (dataset: File) => {
    const onParseCSV = (results: ParseResult<unknown>, file: File) => {
      if (results.errors.length) {
        const csvError = results.errors.map(({ message }) => message).join('. ');
        return setdataseterror(csvError);
      }
      return setdataset(file);
    };

    Papa.parse(dataset, {
      complete: onParseCSV,
      header: true,
      transformHeader: (header: string) => header && header.trim().toLowerCase(),
      skipEmptyLines: true,
    });
  };

  useEffect(() => {
    if (uploadedDataset) setcurrentStatus('Uploading dataset complete');
    if (uploadedNotebook) setcurrentStatus('Uploading notebook complete');
    if (!uploadedDataset) return;
    if (!uploadedNotebook) return;

    setcurrentStatus('Upload complete');
    setuploading(false);
    onComplete();
  }, [uploadedDataset, uploadedNotebook]);

  const hasSelectedFiles = !!(dataset && notebook);

  return (
    <React.Fragment>
      {score && <SuccessModal score={score} close={() => setshowSuccessModal(false)} isOpen={showSuccessModal} />}
      <div>
        <div>
          <div>
            <h3>Upload prediction dataset</h3>
            <p className="text-gray-500 mb-2">
              Submit your dataset here. Make the upload in the format specified by our sample file from the sample
              submissions page.
            </p>
            <Uploader
              options={datasetOptions}
              onComplete={() => setuploadedDataset(true)}
              onError={onError}
              onSelect={onSelectDataset}
              triggerUpload={doUpload}
              triggerReset={resetTrigger}
              uploadParams={datasetUploadParams}
            />
            {dataseterror && (
              <div className="flex justify-between items-center">
                <div />
                {dataseterror && (
                  <div>
                    <InfoLine
                      info={'Make sure your dataset looks like the sample dataset provided'}
                      Icon={FaInfoCircle}
                      color="brand-light"
                    />
                    <InfoLine info={dataseterror} success={false} Icon={FaExclamationCircle} />
                  </div>
                )}
              </div>
            )}
            <h3 className="mt-8">Upload notebook</h3>
            <p className="text-gray-500 mb-2">Submit your code / notebook. Max size: 2mb.</p>
            <Uploader
              options={notebookOptions}
              onComplete={() => setuploadedNotebook(true)}
              onError={onError}
              onSelect={setnotebook}
              triggerUpload={doUpload}
              triggerReset={resetTrigger}
              uploadParams={notebookUploadParams}
            />
            <div className="flex justify-between items-center mt-8 mb-2">
              <div />
              {error && <InfoLine className="font-bold" info={error} success={false} Icon={FaExclamationCircle} />}
            </div>
            {currentStatus && (
              <div className="flex justify-between items-center">
                <div />
                <div className="flex items-center whitespace-nowrap mb-2">
                  <Spinner size="tiny" /> <span className="ml-2">{currentStatus}</span>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between mb-4">
              <div />
              <button
                onClick={onSubmit}
                disabled={uploading || !hasSelectedFiles}
                className={hasSelectedFiles ? '' : 'secondary disabled'}
              >
                {uploading ? 'Uploading' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </React.Fragment>
  );
}
